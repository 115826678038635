:root {
  --ion-background-color: white;
  --ion-tab-bar-color: var(--ion-color-primary-shade);
  --ion-tab-bar-color-selected: rgb(255, 255, 255);
}

ion-tab-bar {
  --background: var(--ion-color-primary);
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.4);
  border-radius: 16px !important;

  height: 50px;
  width: 90%;
  padding-top: 5px;
  padding-bottom: 5px;

  position: relative;
  margin: 0 auto !important;
  border-top: none;
  /* Raise to avoid the iOS bottom bar from covering tab names */
  bottom: 25px;
}

ion-tab-button {
  border-radius: 16px !important;
}
