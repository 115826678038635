.preview-container {
  display: grid;
  grid-template-columns: auto 1fr;
  width: 100vw;
  height: 100vh;
}

.preview-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.iphone-x {
  position: relative;
  max-width: 380px;
  max-height: 780px;
  width: 45vh;
  height: 90vh;
  border-radius: 40px;
  box-shadow: -10px 4px 9px rgba(0, 0, 0, 0.25),
    -25px 10px 30px rgba(0, 0, 0, 0.28);
  border: #1f1f1f solid 11px;
  overflow: hidden;
  flex-shrink: 0;
  background-color: #f9f9f9;
  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
  }

  // home button indicator
  &:after {
    bottom: 7px;
    width: 140px;
    height: 4px;
    background-color: #929292;
    border-radius: 10px;
    z-index: 1000;
  }

  // frontal camera/speaker frame
  &:before {
    top: 0px;
    width: 56%;
    height: 30px;
    background-color: #1f1f1f;
    border-radius: 0px 0px 40px 40px;
    z-index: 1000;
  }

  i,
  b {
    position: absolute;
    display: block;
    color: transparent;
    z-index: 1000;
  }

  // speaker
  i {
    top: 0px;
    left: 50%;
    transform: translate(-50%, 6px);
    height: 8px;
    width: 15%;
    background-color: #101010;
    border-radius: 8px;
    box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
  }

  // camera
  b {
    left: 10%;
    top: 0px;
    transform: translate(180px, 4px);
    width: 12px;
    height: 12px;
    background-color: #101010;
    border-radius: 12px;
    box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);

    &:after {
      content: '';
      position: absolute;
      background-color: #2d4d76;
      width: 6px;
      height: 6px;
      top: 2px;
      left: 2px;
      top: 3px;
      left: 3px;
      display: block;
      border-radius: 4px;
      box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
    }
  }
}

.description {
  margin-left: 3rem;
  flex-shrink: 1;
}

.qr-container {
  height: 160px;
  width: 480px;
  display: grid;
  grid-template-columns: 160px 1fr;
}

.download-text {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.side-bar {
  height: 100vh;
  background: #68b5e7;
  padding: 16px;

  .logo {
    width: 120px;
    height: 120px;
    border-radius: 8px;
  }
}
