.bubble {
  width: 100%;
  position: relative;
  font-size: 0.8em;
  border-radius: 4px;
  padding: 4px 8px;
  text-align: left;
  background-color: bg-sky-200;
  margin-top: 12px;
  margin-bottom: 16px;
  margin-right: 8px;
  display: flex;
  gap: 8px;
  align-items: center;
}

.bubble:before {
  content: '';
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 12px solid #bae6fd;
  border-right: 6px solid transparent;
  border-top: 6px solid #bae6fd;
  border-bottom: 10px solid transparent;
  left: 32px;
  bottom: -16px;
}

.divider {
  background: none;
  padding-top: 4px;
}

.tier-one {
  margin-top: 0;
  color: #0284c7;
  background-color: #bae6fd;
}

.tier-two {
  color: #c72302;
  background-color: #fcc9bb;
}
.tier-two:before {
  border-left: 12px solid #fcc9bb;
  border-right: 6px solid transparent;
  border-top: 6px solid #fcc9bb;
  border-bottom: 10px solid transparent;
}

.tier-three {
  color: #4b3f72;
  background-color: #c1aedf;
}
.tier-three:before {
  border-left: 12px solid #c1aedf;
  border-right: 6px solid transparent;
  border-top: 6px solid #c1aedf;
  border-bottom: 10px solid transparent;
}
