@tailwind base;
@tailwind components;
@tailwind utilities;

.gmnoprint {
  display: none;
}

.gm-control-active {
  display: none;
}

ion-thumbnail {
  --size: 80px;
}

div.toast-message {
  font-weight: 900 !important;
}

ion-loading.custom-loading {
  --background: transparent;
}

ion-modal {
  --max-width: 100%;
}

.disabled {
  @apply opacity-50 pointer-events-none;
}
